<template>
  <div class="mx-auto max-w-lg p-4">
    <div class="py-4">
      <img class="mx-auto" src="../images/logo.png">
    </div>
    <div v-if="membershipStore.needsRenewal" class="text-center text-2xl">
      Your membership has expired
    </div>
    <div v-else-if="membershipStore.valid_to && membershipStore.canRenew" class="flex flex-col items-center">
      <span class="text-xl text-gray-200">Your membership will expire at</span>
      <span class="text-4xl">{{ membershipStore.valid_to_formatted }}</span>
    </div>
    <div class="grid cursor-pointer select-none grid-cols-2 gap-2 py-2 text-base text-brand-500 xs:text-xl">
      <router-link v-if="membershipStore.canRenew" to="/renew" class="col-span-2 flex items-center justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:cash /> Renew your membership now</span>
      </router-link>
      <router-link to="/calendar" class="col-span-2 flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:calendar /> Events</span>
      </router-link>
      <router-link v-if="!membershipStore.needsRenewal" to="/membershipcard" class="flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:credit-card /> Membership card</span>
      </router-link>
      <router-link v-if="!membershipStore.needsRenewal" to="/vehicles" class="flex justify-center bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-ion:car-sport /> Garage</span>
      </router-link>
      <a :href="website_url" target="_blank" class="col-span-2 flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:globe-alt /> Website</span>
      </a>
      <router-link v-if="!membershipStore.needsRenewal" to="/specialVehicle" class="col-span-2 flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-ion:car-sport /> Club Rego Application</span>
      </router-link>
      <a v-if="need_racers_connection" :href="racers_connect_url" class="col-span-2 flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1"><i-heroicons-solid:refresh /> Connect RACERS account</span>
      </a>
      <router-link to="/profile" class="col-span-2 flex justify-center border-l-2 border-brand-500 bg-dark-300 px-2 pb-3 pt-10 hover:text-brand-300">
        <span class="flex items-center gap-1">My Profile</span>
      </router-link>
      <router-link to="/logout" class="absolute bottom-0 right-0">
        <span class="flex items-center gap-1">
          <i-heroicons-solid:logout />
        </span>
      </router-link>
    </div>
    <cgn-ios-pwa-prompt />
  </div>
</template>

<script setup lang="ts">
import { $racersaxios } from '~cognito/plugins/racersapi'

const membershipStore = useClubMemberStore()

const website_url = ref('https://sunshinecoastmotorsportclub.com.au/')

const need_racers_connection = computed(() => {
  return useRacersUserStore().access_token == ''
})
const racers_connect_url = computed(() => {
  return $racersaxios.oauth2AuthorizeUrl()
})

onMounted(() => {
  membershipStore.refresh()
  $racersaxios.get('/api/v1/racers/racer/myacct').then((res) => {
    if (res.data.id) {
      if (membershipStore.racers_id != res.data.id) {
        membershipStore.setRacersId(res.data.id)
      }
    }
  })
})
</script>

<route lang="yaml">
meta:
  requiresAuth: true
</route>
